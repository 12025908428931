// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDY6skj_367onF68SKqVCJs2CT53hHr9Zs",
    authDomain: "zipp-6b2f5.firebaseapp.com",
    databaseURL: "https://zipp-6b2f5-default-rtdb.firebaseio.com",
    projectId: "zipp-6b2f5",
    storageBucket: "zipp-6b2f5.appspot.com",
    messagingSenderId: "866649231863",
    appId: "1:866649231863:web:0d2cd8f2daaae08c009ef2",
    measurementId: "G-EYT67Y38LX"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
