import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CartService } from 'src/app/business-module/@core/services/cart.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CoverageService } from 'src/app/core/services/coverage.service';
import { FirebaseService } from 'src/app/core/services/firebase.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  name: string;
  uid: string;
  address: string;
  userSubs: Subscription;
  userDataSubs: Subscription;
  addressSubs: Subscription;
  changeAddressActive: boolean;

  ordersSubscription: Subscription;
  ordersInProcess: number;
  historyPopupActive: boolean;

  confirmActive: boolean;

  constructor(
    public authService: AuthService,
    public fireService: FirebaseService,
    private router: Router,
    private coverageService: CoverageService,
    private cartService: CartService,
  ) { 
    this.changeAddressActive = false;
  }
  
  ngOnInit(): void {
    this.userSubs = this.authService.getUser().subscribe( 
      user=>{
        if(user){
          this.uid = user.uid;
          // console.log(this.uid);
          
          this.getOrderInProgress(this.uid);
          this.userDataSubs = this.fireService.getUser(user.uid).subscribe(
            ({nombre=''})=>{
              this.name = nombre;
            }
          );
        }else
          this.uid = null;
      }
    );
    this.addressSubs = this.coverageService.coverage$().subscribe(
      coverage =>{
        this.address = coverage? localStorage.getItem('address'): null;
      }
    )
  }
  getOrderInProgress(uid: string){
    this.ordersSubscription = this.fireService.getOrdersInProcess(uid)
    .subscribe( (orders)=>{
      this.ordersInProcess = 0;
      orders.forEach( order=>{
        const statusSubs = this.fireService.getStatusOrden2(order.idOrden,order.idTienda).subscribe(
          status =>{
            if(status){
              this.ordersInProcess +=1;
            }
            statusSubs.unsubscribe();
          }, console.error
        )
      });
    }, console.log)
  }
  showHistoryPopup(){
    this.historyPopupActive = true;
    // console.log('Debería mostrar algo');
    
  }
  
  // Handle Child Components Events
  onHiddenHistoryPopup(){
    this.historyPopupActive = false;
  }
      
  ngOnDestroy(): void {
    this.userSubs.unsubscribe();
    if(this.userDataSubs){
      this.userDataSubs.unsubscribe();
    }
    this.addressSubs.unsubscribe();
  }
  // Evento emitido desde el componente de confirmación
  logout(confirm: boolean){
    if(confirm){
      this.cartService.clearCart()
      this.authService.signOut();
      this.router.navigateByUrl("home");
    }
  }
  
  onClickLogout(){
    this.confirmActive = true;
  }

  navigateToHome(){
    this.router.navigateByUrl('home');

  }
  togleChangeAddressActive(){
    this.changeAddressActive = !this.changeAddressActive;
  }

  hideChangeAddress(){
    this.changeAddressActive = false
  }
}
