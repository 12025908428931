import { Injectable } from '@angular/core';

// Firebase Modules
import { AngularFireDatabase } from '@angular/fire/database';

// RXjs
import { Observable } from 'rxjs';
import {  map, take, tap } from 'rxjs/operators';

// Models
import { SubdomainI } from '../../business-module/@core/models/subdomains.model';
import { BusinessDetailsI, BusinessInfoI } from '../models/bussiness.model';
import { Menu } from '../../business-module/@core/models/menu.model';
import { Product } from '../../business-module/@core/models/product.model';
import { Aditional } from '../../business-module/@core/models/aditional.model';
import { LatLng } from '../../business-module/@core/models/latlng.model';
import { Order } from '../models/order.model';
import { User } from '../models/user.model';
import { OrderInProcess } from '../../business-module/@core/models/order-in-process.model';
import { UserDriver } from '../models/user-driver.model';
import { Parcel, ParcelStatus } from '../models/parcel.model';
import { PaymentMethodType } from 'src/app/payment/models';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
    // GORQF6ltl0Wrtzfvkt769VggkWD3
  // oA8Y6kwdyTRKf0mlnA704FLanuM2
  private _businessID:       string;
  private _businessInfo: BusinessInfoI;
  
  constructor(
    private db: AngularFireDatabase,
  ) { 
  }

  get estadoYMunicipio(){
    const estado = localStorage.getItem('estado');
    const municipio = localStorage.getItem('municipio');
    return [estado, municipio];
  }

  set businessInfo(businessInfo : BusinessInfoI){
    this._businessInfo = businessInfo;
  }
  set businessID(id: string){
    this._businessID = id;    
  }
  
  get businessInfo(): BusinessInfoI{
    return this._businessInfo;
  }
  get businessID(): string{
    return this._businessID;
  }

  // Método que verifica si un negocio ha registrado su subdominio
  // @return: id del negocio si el negocio tiene registrado su subdominio,
  // Excepción en caso contrario
  existSubdomain(businessName: string): Observable<string>{
    const subdomains$ = this.db.list<SubdomainI>('subdominios').valueChanges();

    return subdomains$.pipe(
      map( subdomains=>{   
        const subdomain = subdomains.find( ({idsub}) => idsub == businessName );
        if(subdomain)   return subdomain.idNegocio;

        else throw new Error('Dominio no registrado');
      })
    );
  }

  // Obtiene la información de un negocio dado su id
  // Devuelve un Observable de tipo BusinessInfoI
  fetchBusinessInfo(){
    const path = `Business/${this._businessID}`;
    return this.db.object<BusinessInfoI>(path).valueChanges();
  }
  
  fetchBusinessInfoPromise(): Promise<BusinessInfoI>{
    return new Promise( (resolve, reject)=>{
      const busInfoSubscription = this.fetchBusinessInfo().subscribe( busInfo =>{
        resolve(busInfo);
        busInfoSubscription.unsubscribe();
      }, reject);
    });
  }
  
  // Obtener los detalles del negocio(banner, logo, etc.)
  getBusinessDetails(){
    const { estado, municipio, tipo_negocio } = this._businessInfo;
    const path = `${estado}/${municipio}/${tipo_negocio}/${tipo_negocio}/${this._businessID}`;

    return this.db.object<BusinessDetailsI>(path).valueChanges();
  }
  // Obtener los detalles del negocio(banner, logo, etc.)
  getBusinessDetailsPromise(): Promise<BusinessDetailsI>{
    return new Promise( (resolve, reject)=>{
      const busDetailsSubscription = this.getBusinessDetails().subscribe( busDetails =>{
        resolve(busDetails);
        busDetailsSubscription.unsubscribe();
      }, reject);
    });
  }

  getMenu(menuId: string) : Observable<Menu> {
    const { estado, municipio, tipo_negocio } = this._businessInfo;
    const path = `${estado}/${municipio}/${tipo_negocio}/Menus/${this._businessID}/InfoMenus/${menuId}`;

    return this.db.object<Menu>(path).valueChanges();
  }

  // Obtener la información de los menús.
  // Devuelve un observable de tipo Menu []
  getMenus(isNewUser: boolean = false): Observable<Menu[]>{
    const { estado, municipio, tipo_negocio } = this._businessInfo;
    const path = `${estado}/${municipio}/${tipo_negocio}/Menus/${this._businessID}/InfoMenus`;

    return this.db.object(path).valueChanges().pipe(
      map( this.menusToArray ),
      map( menus => this.removeInactiveMenus(menus, isNewUser) ),
      map(this.orderByPriority),
      map( this.movePromotionMenusToFront ),
    );
  }

  // remove the promotion menus for users who are no longer new in the app.
  private removeInactiveMenus(menus: Menu[], isNewUser: boolean): Menu[] {
    return menus.filter(menu => !menu.isNewUserPromotion || isNewUser);
  }

  private orderByPriority(menus: Menu[]): Menu[] {
    return menus.sort((a, b) => a.priority - b.priority);;
  }
  

  
  private movePromotionMenusToFront(menus: Menu[]): Menu[] {
    const arrangedMenus = [];
    menus.forEach(menu => {
      if (menu.isNewUserPromotion) {
        arrangedMenus.unshift(menu)
      } else {
        arrangedMenus.push(menu);
      }
    })
    return arrangedMenus;
  }

  // Obtiene los productos de un menú
  // Retorna un array de id's de productos válidos del menú
  getIDsProductsMenu(menuID: string){
    const { estado, municipio, tipo_negocio } = this._businessInfo;
    const path = `${estado}/${municipio}/${tipo_negocio}/Menus/${this._businessID}/Menus/${menuID}`;

    return this.db.list(path).valueChanges().pipe(
      map((productsMenu: [])=>{
        const products = productsMenu.filter( (product: any) => product.activo == 1)
        return products.map( (product: any) => product.idProducto)
      })
    )
  }

  // Obtener los productos de un menu
  // params: idsProducts: ids de los productos validos
  getProductsMenu(idsProducts: string[], isFromNewUserPromotionMenu: boolean){
    const { estado, municipio, tipo_negocio } = this._businessInfo;
    const path = `${estado}/${municipio}/${tipo_negocio}/Productos/${this._businessID}`;

    return this.db.list(path).valueChanges().pipe(
      map( (products: Product[])=>{
        return products.filter( product => {
          // Tomar el producto si pertenece al menu y si es válido
          return idsProducts.includes(product.id) && product.activo===1;
        }).map(product => ({ ...product, isFromNewUserPromotionMenu }));
      })
    )
  }
  
  // Obtener los opcionales de un producto
  getOptionals(productId: string): Observable<Aditional[] | null>{
    const { estado, municipio, tipo_negocio } = this._businessInfo;
    const path = `${estado}/${municipio}/${tipo_negocio}/Opcionales/${this._businessID}/${productId}`;

    return this.db.object(path).valueChanges().pipe(
      map( (optionals: Object)=>{
        if(optionals) return this.aditionalsToArray(optionals)
        return null;
      })
    )
  }
  // Obtiene un arreglos de ids de todos los productos válidos.
  getIdsProductsValid(){
    const { estado, municipio, tipo_negocio } = this._businessInfo;
    const path = `${estado}/${municipio}/${tipo_negocio}/Menus/${this._businessID}/Menus`;
    return this.db.object<Object>(path).valueChanges().pipe(
      map( menus => {
        const idsValid: string[] = [];
        for (const menuKey in menus) {
          for (const productKey in menus[menuKey]) {
            if(menus[menuKey][productKey].activo == 1)
              idsValid.push(menus[menuKey][productKey].idProducto)
          }
        }
        return idsValid;
      })
    )

  }

  getObligatories(productId: string): Observable<Aditional[] | null>{
    const { estado, municipio, tipo_negocio } = this._businessInfo;
    const path = `${estado}/${municipio}/${tipo_negocio}/Obligatorias/${this._businessID}/${productId}`;

    return this.db.object(path).valueChanges().pipe(
      map( (obligatories: Object)=>{
        if(obligatories) return this.aditionalsToArray(obligatories)
        return null;
      })
    )
  }

  // Transforma el objeto de opcionales u obligatorios a un arreglo
  private aditionalsToArray(aditionals: Object){
    const aditionalsArray: Aditional[] =[];
    for (const key in aditionals) {
      // Obtener solo los adicionales válidos
      if(aditionals[key].activo == 1)
        aditionalsArray.push({
          id: key,
          nombre: aditionals[key].nombre,
          agotado: aditionals[key].agotado,
          categoria: aditionals[key].categoria,
          costo: aditionals[key].costo
        });
    }
    return aditionalsArray;
  }

  getProduct(productID: string): Observable<Product>{
    const { estado, municipio, tipo_negocio } = this._businessInfo;
    const path = `${estado}/${municipio}/${tipo_negocio}/Productos/${this._businessID}/${productID}`;
    return this.db.object<Product>(path).valueChanges();
  }


  getPaymentsMethod(): Promise<PaymentMethodType[]>{
    const path = `payments`;
    return new Promise( async (resolve, reject)=>{
      try {
        const response = await this.db.list(path).query.get();
        const paymentsObj = response.val()
        if(paymentsObj){
          const payments: PaymentMethodType[]= [];
          for (const key in paymentsObj) {
            if(paymentsObj[key].active){
              paymentsObj[key].id = key;
              payments.push(paymentsObj[key]);
            }
          }          
          resolve(payments);
        }else reject('No existen métodos de pago')

      } catch (error) {
        reject('Ocurrió un error al obtener los métodos de pago')
      }
    });
  }
  
  // Get the Latitude and longitude of the Business
  getLatLngBusiness(businessID: string): Promise<LatLng>{
    const path = `Business/${businessID}`;
    return new Promise( async(resolve, reject)=>{
      try {
        const response = await this.db.object(path).query.get();
        const businessInfo = response.val();
        if(businessInfo){
          const {latitud, longitud} = businessInfo;
          resolve(new LatLng(latitud,longitud));
        }else reject('El negocio no existe');

      } catch (error) {
        reject(error);
      }
    });
  }

  // Get shiping rates elements: dropoff, pickup, distance
  getRates(businessID: string){
    const [estado, municipio] = this.estadoYMunicipio;
    const path= `deliveryFee/${estado}/${municipio}`;
    return this.db.object(path).query.get();
  }

  // Método que recibe un objeto con la siguiente estructura 
  // { idMenu: { activo: 1, titulo: 'Bebidas' } ,...} 
  // Devuelve un arreglo de tipo Menu que contiene los menús activos
  private menusToArray(menus: Object): Menu[]{
    let menusArray : Menu[] = [];
    for (const key in menus){
      if(menus[key].activo == 1 || !!menus[key].isNewUserPromotion) {
        const { titulo, isNewUserPromotion, priority } = menus[key];
        menusArray.push({ 
          id: key, 
          menuName: titulo, 
          isNewUserPromotion,
          priority
        })
      }
    }
    return menusArray;
  }

  getIdPush(){
    return this.db.createPushId();
  }
  saveOrder(order: Order){
    const itemRef = this.db.object(`/OrdenesTiendas/${order.storeId}/${order.idOrden}`);
    return itemRef.update(order);
  }

  saveUser(user: User, uid: string){
    const itemRef = this.db.object(`/usuarios/${uid}`);
    return itemRef.update(user);
  }

  updateUser(user: Partial<User>, uid: string) {
    const itemRef = this.db.object(`/usuarios/${uid}`);
    return itemRef.update(user);
  }

  getUser(uid: string): Observable<User>{
    return this.db.object<User>(`/usuarios/${uid}`).valueChanges();
  }
  async isUserRegistered(uid: string): Promise<any>{
    const itemRef = this.db.object(`/usuarios/${uid}/register`);
    return itemRef.query.get()
  }


  getOrdersInProcess(userId: string){
    const path = `/pedidosUsuariosEnCurso/${userId}/proceso/`;
    return this.db.list<OrderInProcess>(path).valueChanges(); 
  }

  saveOrderInProgress(userId: string, orderInProcess: OrderInProcess){
    // const idPush = this.getIdPush();
    const path = `/pedidosUsuariosEnCurso/${userId}/proceso/${orderInProcess.idOrden}`;
    const itemRef = this.db.object(path);
    return itemRef.update(orderInProcess);
  }
  getStatusOrder(orderId: string): Observable<string | undefined>{
    const path  = `OrdenesTiendas/${this.businessID}/${orderId}`;
    return this.db.object(path).valueChanges().pipe(
      map( (order: Order) =>{
        return order?.statusOrden
      })
    );
  }
  getStatusOrden2(orderId: string, businessID: string): Observable<string | undefined>{
    const path  = `OrdenesTiendas/${businessID}/${orderId}`;
    return this.db.object(path).valueChanges().pipe(
      map( (order: Order) =>{
        return order?.statusOrden
      })
    );
  }

  getOrder(orderId: string): Observable<Order>{
    const path = `OrdenesTiendas/${this.businessID}/${orderId}`;
    return this.db.object<Order>(path).valueChanges();
  }
  getOrder2(orderId: string, busId: string): Observable<Order>{
    const path = `OrdenesTiendas/${busId}/${orderId}`;
    return this.db.object<Order>(path).valueChanges();
  }



  getSupportPhone(): Observable<string>{
    const path = `SupportPhones/-M6lsJTLERCtoItkoSiu/phone`;
    return this.db.object<string>(path).valueChanges();
  }

  getUserDriver(userId: string){
    const path =`usuariosDriver/${userId}`;
    return this.db.object<UserDriver>(path).valueChanges();
  }

  getHistorialOrders(userId): Observable<Order[]>{
    const path = `historialUsuarios/${userId}/historial`
    return this.db.list<Order>(path).valueChanges();
  }

  // Obtener un arreglo de id's de las paqueterías de un negocio
  getParcelsId(businessId: string): Promise<string[]>{
    return new Promise( (resolve, reject)=>{
      const path= `negocioPaqueteria/${businessId}/paqueterias`;

      const parcelsSubscription = this.db.list<ParcelStatus>(path).valueChanges()
      .pipe( 
        map( (parcerls: ParcelStatus [])=> {
          // Filtrar sólo aquellos que tengan el estado en true
          const parcelsActive = parcerls.filter( parcel => parcel.estado );
          // Obtener un arreglo de ids de las paqueterias.
          return parcelsActive.map( parcel => parcel.id );
        })
      ).subscribe(
        (parcelsId => {
          parcelsSubscription.unsubscribe();
          resolve(parcelsId)

        }),
        ( err => reject(err))
      )
    });

  }

  // Obtener la información de una paqueteria
  getParcel(parcelId: string): Observable<Parcel>{
    const path= `paqueteria/${parcelId}`;

    return this.db.object<Parcel>(path).valueChanges()
    .pipe( 
      map( parcel=> {
        parcel.id = parcelId;
        return parcel;
      })
    )
  }

  businessHasDrivers(businessId: string):Promise<boolean>{
    return new Promise( (resolve, reject)=>{
      const path= `driversNegocio/${businessId}`;
      const driversSubscription = this.db.list(path).valueChanges()
      .subscribe(
        ((drivers: any []) => {          
          driversSubscription.unsubscribe();
          resolve(drivers.length>0)

        }),
        ( err => reject(err))
      )
    });
  }
  getPopupAdsUrl() {
    return new Promise( (resolve, reject)=>{
      const path= "configuracion/promoImage";
      this.db.object(path).valueChanges().pipe(
        take(1)
      )
      .subscribe(
        ((promoImage: string) => {   
          resolve(promoImage)
        }),
        ( err => reject(""))
      )
    });
  }

}
