<app-header></app-header>
<div class="container mt-5">
  <h1>Política de Privacidad de CORPO SUBSTORE SA DE CV (ZIPP.mx)</h1>
  <hr />
  <div class="section mt-4">
    <h2>Responsable del Tratamiento de Datos Personales</h2>
    <p>
      CORPO SUBSTORE SA DE CV, con domicilio en PISO 7, RIO NILO No. 90, Colonia CUAUHTÉMOC, C.P. 06500, entre RIO VOLGA y REFORMA, CUAUHTÉMOC, CIUDAD DE MÉXICO, es responsable del uso y protección de sus datos personales recabados a través del servicio de nuestra página web zipp.mx.
    </p>
  </div>

  <div class="section mt-4">
    <h2>
      Finalidades del Tratamiento de Datos Personales
    </h2>
    <p>
      Los datos personales recabados se utilizan para procesar y entregar pedidos de Subway a su oficina, crear y administrar cuentas de usuarios, entre otras finalidades detalladas en el documento.
    </p>
  </div>

  <div class="section mt-4">
    <h2>
      Datos Personales Recolectados
    </h2>
    <p>
      Los siguientes datos personales pueden ser recolectados: Nombre, fecha de nacimiento, nacionalidad, domicilio, teléfono celular, correo electrónico, edad, fotografía, información financiera.
    </p>
  </div>

  <div class="section mt-4">
    <h2>
      Transferencia de Datos Personales
    </h2>
    <p>
      Sus datos personales pueden ser compartidos con autoridades gubernamentales, compañías afiliadas o subsidiarias, y terceros para fines legales, de análisis de perfiles de usuario, para fines estadísticos, y para el cumplimiento de relaciones jurídicas.
    </p>
  </div>

  <div class="section mt-4">
    <h2>
      Ejercicio de Derechos ARCO
    </h2>
    <p>
      Usted tiene derecho a acceder, rectificar, cancelar u oponerse al tratamiento de sus datos personales. Para ejercer estos derechos, puede contactarnos a través de <a href="mailto:inventarios@corposubstore.com.mx">inventarios@corposubstore.com.mx</a>.
    </p>
  </div>

  <div class="section mt-4">
    <h2>
      Revocación del Consentimiento
    </h2>
    <p>
      Puede revocar su consentimiento para el tratamiento de sus datos personales contactando a <a href="mailto:inventarios@corposubstore.com.mx">inventarios@corposubstore.com.mx</a>.
    </p>
  </div>

  <div class="section mt-4">
    <h2>
      Uso de Tecnologías de Rastreo
    </h2>
    <p>
      Nuestra página web y aplicación utilizan cookies y tecnologías similares para mejorar su experiencia de usuario y ofrecer productos y servicios personalizados.
    </p>
  </div>
  
  <div class="section mt-4">
    <h2>
      Cambios a la Política de Privacidad
    </h2>
    <p>
      Esta política puede ser modificada y las actualizaciones se comunicarán a través de <a href="mailto:inventarios@corposubstore.com.mx">inventarios@corposubstore.com.mx</a>. 
    </p>
  </div>

  <div class="section mt-4">
    <h2>
      Fecha de la Última Actualización
    </h2>
    <p>
      31 de Enero de 2024.
    </p>
  </div>

  <div class="section mt-4 mb-5">
    <h2>
      Contacto
    </h2>
    <p>
      Para cualquier consulta o aclaración, contáctenos a través de <a href="mailto:inventarios@corposubstore.com.mx">inventarios@corposubstore.com.mx</a>.
    </p>
  </div>

</div>