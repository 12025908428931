import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoverageService {
  private subject: BehaviorSubject<boolean>;
  constructor() { 
    this.init()
  }
  // Get and emit the current value of coverage.
  private init(){
    const coverage = JSON.parse(localStorage.getItem('coverage'));
    this.subject = new BehaviorSubject<boolean>(coverage);
  }

  set coverage(isInside: boolean){
    localStorage.setItem('coverage', JSON.stringify(isInside));
    // Emitir el valor.
    this.subject.next(isInside);
  }
  coverage$(): Observable<boolean>{
    return this.subject.asObservable();
  }
}
