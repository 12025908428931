<!-- Con navbar-expand el navbar se expande a partir del breakpoint lg, ya que sigue el enfoque mobile first. -->

<nav class="navbar navbar-expand-lg navbar-dark">
  <div class="container">
    <a class="small navbar-brand text-light" (click)="navigateToHome()">
      <img 
          src="../../../../assets/images/logo-transaparente.png" 
          alt="Logo supplai" 
          width="48" 
          height="48" 
          class="img-fluid"
      >
      <span *ngIf="!uid" class="title ml-3">administrada por CORPO SUBSTORE SA DE CV</span>
    </a> 
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" >
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto align-items-center small">
        <li class="nav-item">
          <a class="nav-link mr-3"
            *ngIf="uid"
          >Hola <span class="font-weight-bold">{{name}}</span>!</a>
        </li>
        <li class="navbar-item mr-3 privacy-policy" *ngIf="!uid">
          <a href="/privacy-policy">Política de privacidad</a>
        </li>
        <!-- <li class="nav-item" *ngIf="address">
          <a class="nav-link mr-3" >
            <img src="assets/images/pin.svg" alt="Location icon" width="15px" height="">
            <span class="" (click)="togleChangeAddressActive()">{{address|shorten:25}}...</span>  

            <app-change-address (hideAddressEvent)="hideChangeAddress()" *ngIf="changeAddressActive"></app-change-address>
          </a>
        </li> -->
        <li class="navbar-item cart history mr-3" *ngIf="uid">
          <button 
              class="btn icon" 
              (click)="showHistoryPopup()"
          >
              <img class="img icon-img" src="assets/images//clipboard.svg" alt="history" />
              <p 
                class="notification"
                *ngIf="ordersInProcess>0"
              >{{ordersInProcess}}</p>
          </button>
        </li>
        <li class="nav-item">
          <a 
            class="nav-link" 
            tabindex="-1" aria-disabled="true"
            *ngIf="uid"
            (click)="onClickLogout()"
          >Cerrar Sesión</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<app-confirm
  *ngIf="confirmActive"
  (confirmEvent)="logout($event)"
></app-confirm>

<div *ngIf="historyPopupActive">
  <app-history 
      (hiddenHistoryEvent)="onHiddenHistoryPopup()"
  ></app-history>
</div>