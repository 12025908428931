<div class="popup-container popup-history-container">
    <div class="popup">
        <div class="btn icon btn-close" (click)="hiddenHistory()">
            <img class="img" src="assets/images/close.svg" alt="Cerrar ventana">
        </div>
        <nav class="navbar">
            <a 
                class="in_progress"
                (click)="switchToHistory()"
                [class.active]="!historyActive"
                >En proceso</a>
            <a 
                class="history_orders" 
                (click)="switchToInProgress()" 
                [class.active]="historyActive"
            >Historial</a>
        </nav>
        <div class="history-container">
            <div class="in-progress-content" *ngIf="!historyActive">
                <ul 
                    class="in-progress-list" 
                    *ngFor="let order of ordersInProgress; let i = index"
                >
                    <li 
                        class="in-progress-item" 
                        *ngIf="order.status"
                        (click)="redirectToStatusOrder(order.idOrden, order.idTienda)"
                    >
                        <div class="status">
                            <div>{{ order.status }}</div>
                            <div class="in-progress-item-date">{{ order.fecha }}</div>
                        </div>
                        <div class="total-order-in-progress">${{ order.costo }} MXN</div>
                    </li>
               </ul>
            </div>
            <div class="history-orders-content" *ngIf="historyActive">
                <ul 
                    class="in-progress-list" 
                >
                    <li 
                        *ngFor="let order of historial; let i = index" 
                        (click)="toggleAccordion(i)"
                    >
                        <div class="historial-item" [class.ticket-active]="indexCurrentTicket==i && statusTicket" >
                            <div class="status">
                                <div>{{ order.fecha }}</div>
                                <div class="historial-item-date">{{ order.motivo_cancelacion }}</div>
                            </div>
                            <div class="total-order-in-progress">${{ getSubtotal(order) + order.precioEntrega }} MXN</div>
                        </div>
                        <div *ngIf="statusTicket && indexCurrentTicket == i">
                            <div class="ticket-content">
                                <ul>
                                    <li
                                        *ngFor="let product of order.productos"
                                    >    
                                        <div class="product-order-details">
                                            <h4 class="detail-name"> {{ product.cantidad }} x {{ product.titulo }}</h4>
                                            <h5 class="detail-cost">${{ product.cantidad * product.costoUnitario }}.00 MXN</h5>                     
                                        </div>
                                        <ul class="aditionals-details-container">
                                            <li class="aditional" *ngFor="let oblig of product?.obligatorios">
                                                <h4 >{{ oblig.nombre }}</h4>
                                                <div class="aditional-details">
                                                    <h4 class="detail-name"> {{ oblig.valor }}</h4>
                                                    <h5 class="detail-cost">${{ oblig.costo> 0 ? oblig.costo : 0}}.00 MXN</h5>         
                                                </div>
                                            </li>
                                        </ul>
                                        <ul class="aditionals-details-container">
                                            <li class="aditional" *ngFor="let opcional of product?.opcionales">
                                                <h4 >{{ opcional.nombre }}</h4>
                                                <div class="aditional-details">                                
                                                    <h4 class="detail-name">{{ opcional.valor }}</h4>
                                                    <h5 class="detail-cost">${{ opcional.costo> 0 ? opcional.costo : 0}}.00 MXN</h5>   
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <hr>
                                <div class="subtotal">
                                    Subtotal: 
                                    <strong>${{ getSubtotal(order) }} MXN</strong>
                                </div>
                                <div class="shiping-rates">
                                    Costo de envío: 
                                    <strong>${{ order.precioEntrega }} MXN</strong>
                                </div>
                                <hr>
                                <div class="total">TOTAL: ${{ getSubtotal(order) + order.precioEntrega }}.MXN </div>
                            </div>
                        </div>
                    </li>
               </ul>
            </div>
        </div>
    </div>
</div>