import { Injectable } from '@angular/core';
import { Cart } from '../models/cart.model';

import { ProductOrder } from '../models/product-order.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  numProducts: number;
  // This variable name specifies that it is related to a promotional product
  // and indicates whether it has been picked or added to the cart by a new user.
  isPromoProductPicked$ = new BehaviorSubject<boolean>(false);
  constructor() {
    const cart = this.getCart();
    this.checkIfNewUserPromoProductPicked(cart);
  }

  getIsNewUserPromoProductPickedObservable(): Observable<boolean> {
    return this.isPromoProductPicked$.asObservable();
  }

  checkIfNewUserPromoProductPicked(cart: Cart) {
    const promotionProduct = cart?.products.find(product => !!product?.isFromNewUserPromotionMenu);
    this.isPromoProductPicked$.next(!!promotionProduct);
  }

  validateCart(businessId: string){
    let cart = this.getCart();

    if(cart){
      if(cart?.businessId != businessId){
        this.clearCart();
        cart = {
          businessId: businessId,
          products: []
        }
      }
    }else{
      cart = {
        businessId: businessId,
        products: []
      }
    }
    this.numProducts = cart.products.length;
    this.saveCart(cart);
  }

  // Añadir una orden de producto al carrito
  addProductOrder(productOrder: ProductOrder){
    let cart = this.getCart();
    if(cart){
      cart.products.push(productOrder);
    }else{
      cart = { products: [productOrder] }
    }

    this.numProducts = cart.products.length;
    this.checkIfNewUserPromoProductPicked(cart);
    this.saveCart(cart);
  }

  // Guardar el carrito en el localstorage
  private saveCart(cart: Cart){
    localStorage.setItem('carrito', JSON.stringify(cart));
  }

  getCart(): Cart{
    return JSON.parse(localStorage.getItem('carrito'));
  }

  // Actualizar los productos del carrito
  updateProducts(products: ProductOrder[]){
    let cart = this.getCart();
    if(cart){
      cart.products = products;
      this.checkIfNewUserPromoProductPicked(cart);
      this.saveCart(cart);
    }
  }

  // Obtener los productos del carrito
  getProducts(): ProductOrder[]{
    const cart = this.getCart();
    if(cart) return cart.products;
    return null;
  }

  getCostTotal(): number{
    let total = 0;
    const cart = this.getCart();
    cart?.products.forEach( product => {
      total+= product.total;
    })
    return total;
  }


  // Obtiene el total de productos del carrito
  getTotalProducts(): number{
    const cart = this.getCart();
    if(cart)  return cart.products.length
    return 0;
  }

  clearCart(){
    const carrito = JSON.parse(localStorage.getItem('carrito'));

    localStorage.setItem('carrito', JSON.stringify({
      businessId: carrito.businessId,
      products: []
    }));
    this.numProducts = 0;
    this.isPromoProductPicked$.next(false);
  }

  getBusinessId(): string{
    const cart = this.getCart();
    return cart.businessId;
  }
}
