import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from './core/guards/no-auth.guard';
import { PrivacyPolicyComponent } from './home/pages/privacy-policy/privacy-policy.component';
import { AboutComponent } from './home/pages/about/about.component';

const routes: Routes = [
  { 
    path: 'home' ,
    canActivate: [NoAuthGuard],
    loadChildren: ()=> import('./home/home.module').then( m => m.HomeModule)
  },
  { 
    path: 'about' ,
    component: AboutComponent,
  },
  { 
    path: 'privacy-policy' ,
    component: PrivacyPolicyComponent,
  },
  { 
    path: ':businessdomain',  
    loadChildren: ()=> import('./business-module/business.module').then( m => m.BusinessModule)
  },
  { path: '**', redirectTo: 'BiQk9nPV0rUBTsNLQ6jB7p0NCHA2' }
]

@NgModule({
  declarations: [],
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }