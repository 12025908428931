import { Injectable } from '@angular/core';

import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase';
import { CoverageService } from './coverage.service';

@Injectable()
export class AuthService {

  constructor(
    private auth: AngularFireAuth,
    private coverageService: CoverageService
  ) { 
  }

  signIn(){
    return this.auth.signInAnonymously();   
  }
  signOut(){
    this.auth.signOut();
    localStorage.removeItem('estado');
    localStorage.removeItem('municipio');
    localStorage.removeItem('nom_poligono');
    localStorage.removeItem('phone');
    this.coverageService.coverage = false;
  }

  getCurrentUser(){
    return this.auth.currentUser;
  }
  getUser(){
    return this.auth.user;
  }
  signInWithPhoneNumber(phoneNumber: string, appVerifier: firebase.auth.RecaptchaVerifier){
    return this.auth.signInWithPhoneNumber(phoneNumber, appVerifier);
  }
}

