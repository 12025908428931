import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent {
  @Output() confirmEvent= new EventEmitter<boolean>();
  constructor() { }

  onConfirm(){
    this.confirmEvent.emit(true);
    // console.log('Afirmativo');
    
  }
  onCancel(){
    this.confirmEvent.emit(false);
    // console.log('Cancelar');
  }
}
