<app-header></app-header>
<div class="container mt-5">

  <h1>Acerca de Nosotros</h1>
  
  <hr />

  <div class="section mt-4">
    <h2>
      Bienvenidos a ZIPP.mx
    </h2>
    <p>
      La innovadora plataforma de entrega que transforma la manera en que disfrutas de tus productos favoritos de Subway. Somos una marca orgullosamente operada y administrada por "CORPO SUBSTORE SA DE CV", una empresa líder en soluciones de entrega y logística.
    </p>
  </div>

  <div class="section mt-4">
    <h2>
      Nuestra Misión
    </h2>
    <p>
      Como parte de "CORPO SUBSTORE SA DE CV", ZIPP.mx se compromete a mantener los más altos estándares de calidad y servicio que nuestros clientes esperan de nosotros. Nuestra misión es simplificar tu vida mediante un servicio de entrega rápido, seguro y personalizado, garantizando que tus pedidos de Subway lleguen frescos y deliciosos cada vez.
    </p>
  </div>
  
  <div class="section mt-4">
    <h2>
      Nuestro Compromiso
    </h2>
    <p>
      La confianza que depositas en ZIPP.mx es el reflejo de la solidez y el compromiso de "CORPO SUBSTORE SA DE CV" para contigo. Cada pedido que realizas a través de ZIPP.mx es un compromiso que tomamos seriamente, asegurando la calidad y la satisfacción que solo una empresa con la experiencia y la infraestructura de "CORPO SUBSTORE SA DE CV" puede ofrecer.
    </p>
  </div>
  
  <div class="section mt-4 mb-5">
    <h2>
      Transparencia y Confianza
    </h2>
    <p>
      Queremos que te sientas seguro al elegir ZIPP.mx para tus entregas de Subway. Es por eso que hacemos transparente nuestra relación directa con "CORPO SUBSTORE SA DE CV", proporcionando un servicio confiable y consistente que conoces y en el que puedes confiar. Agradecemos tu preferencia y nos comprometemos a seguir innovando para ofrecerte lo mejor en entrega de comidas. Para cualquier pregunta o más información sobre nuestra relación corporativa, no dudes en contactarnos.
    </p>
  </div>
</div>