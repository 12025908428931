import { Component, EventEmitter, OnInit, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrderInProcess } from 'src/app/business-module/@core/models/order-in-process.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { Order } from 'src/app/core/models/order.model';
import { FirebaseService } from 'src/app/core/services/firebase.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit, OnDestroy {
  @Output() hiddenHistoryEvent = new EventEmitter<void>();

  historyActive: boolean;
  ordersInProgress: OrderInProcess[];
  historial: Order[];
  ordersSubscription: Subscription
  statusSubscriptions: Subscription[] = [];
  uid: string;

  statusTicket : boolean;
  indexCurrentTicket: number;

  constructor(
    public firebaseService: FirebaseService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { 
    this.statusTicket = false;
  }
  
  ngOnInit(): void {
    this.getOrdersInProcess();
  }
  ngOnDestroy(): void {
    if(this.ordersSubscription){
      this.ordersSubscription.unsubscribe();
    }
    this.statusSubscriptions.forEach( subs=>{
      subs.unsubscribe();
      
    })
  }

  redirectToStatusOrder(orderId: string, busId: string){
    console.log(orderId, busId);
    
    this.route.url.subscribe( url=>{
      const currentPath = url[0]?.path;
      this.router.navigate(
        [busId,'tracking', busId, orderId]
      );
    }, console.log)
  }
  
  async getOrdersInProcess(){
    const userSubscription = this.authService.getUser().subscribe( 
      user => {
        if(user){
          this.uid = user.uid;
          this.ordersSubscription = this.firebaseService.getOrdersInProcess(this.uid)
          .subscribe( 
            orders => {
              this.ordersInProgress = orders;
              
              this.ordersInProgress.forEach( order=>{
                const statusSubs = this.firebaseService.getOrder2(order.idOrden,order.idTienda).subscribe(
                  orderBD =>{
                    order.status = orderBD?.statusOrden;
                    
                    if(orderBD?.statusOrden){
                      console.log(order);
                    }
                    
                    order.fecha = `${orderBD?.fecha}  ${orderBD?.hora}`; 
                    this.statusSubscriptions.push(statusSubs);
                  }, console.error
                )
              })
            },
            console.error
          )        
        }
        userSubscription.unsubscribe();
      },
      console.error
    )
  }

  getHistorialOrders(){
    if(this.uid){
      const histSubscription = this.firebaseService.getHistorialOrders(this.uid)
      .subscribe( historial=>{
        this.historial = historial;
        histSubscription.unsubscribe();
      }, console.log)
    }
  }

  hiddenHistory(){
    this.hiddenHistoryEvent.emit();
  }

  // Switch between history and in process
  switchToInProgress(){
    this.historyActive = true;
    this.getHistorialOrders();
  }
  switchToHistory(){
    
    this.historyActive = false;
  }
   getSubtotal(order: Order){
    let subtotal = 0;
    order.productos.forEach( product =>{
      subtotal+= (product.costoUnitario * product.cantidad);
    })
    return subtotal
  }
  
  // Activar o desactivar un accordion
  toggleAccordion(ticketIndex: number){
    this.statusTicket = !this.statusTicket;
    this.indexCurrentTicket = ticketIndex;
  }
}
