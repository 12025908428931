interface LatLngI{
    lat: number;
    lng: number;
}

export class LatLng implements LatLngI{
    lat: number;
    lng: number;
    calledFrom?: string; 
    
    constructor(lat: number, lng: number){
        this.lat = lat;
        this.lng = lng;
    }
    public toString(): string{
        return `${this.lat}, ${this.lng}`;
    }
}
